<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <!-- Shift Log Management Form -->
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(submitForm)">
        <b-row>
          <!-- Hidden Field: userID -->
          <b-col md="6">
            <b-form-group>
              <b-form-input
                id="userID"
                v-model="shiftLogData.userID"
                type="hidden"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Input Date -->
          <b-col md="6">
            <b-form-group label="Date" label-for="inputDate">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="inputDate"
                  v-model="shiftLogData.inputDate"
                  type="date"
                  placeholder="Select date"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Start Time -->
          <b-col md="6">
            <b-form-group label="Start Time" label-for="startTime">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="startTime"
                  v-model="shiftLogData.startTime"
                  type="time"
                  placeholder="Select start time"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: End Time -->
          <b-col md="6">
            <b-form-group label="End Time" label-for="endTime">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="endTime"
                  v-model="shiftLogData.endTime"
                  type="time"
                  placeholder="Select end time"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Client Name -->
          <b-col md="6">
            <b-form-group label="Client Name" label-for="clientID">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-select
                  id="clientID"
                  v-model="shiftLogData.clientID"
                  :options="clients"
                  label="name"
                  track-by="id"
                  :reduce="client => client.id"
                  placeholder="Select a client"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Site Address -->
          <b-col md="6">
            <b-form-group label="Site Address" label-for="siteAddress">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="siteAddress"
                  v-model="shiftLogData.siteAddress"
                  placeholder="Enter site address"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Time - Details -->
          <b-col md="12">
            <b-form-group label="Time - Details" label-for="timeDetail">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-textarea
                  id="timeDetail"
                  v-model="shiftLogData.timeDetail"
                  placeholder="Enter time details"
                  rows="3"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Image Upload -->
        <b-row>
          <b-col>
            <b-form-group label="Upload Images">
              <b-form-file
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose one or more images..."
                multiple
                @change="handleFiles"
              />
            </b-form-group>
            <div v-if="imageUrls.length > 0">
              <div v-for="(url, index) in imageUrls" :key="index">
                <img :src="url" class="img-thumbnail" :alt="`Shift Log Image ${index + 1}`" style="max-width: 150px; margin-right: 10px;">
              </div>
            </div>
          </b-col>
        </b-row>

        <b-button type="submit" variant="primary">
          Submit Shift Log
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormFile, BFormTextarea, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      shiftLogData: {
        userID: '', // This should be set based on the user context or passed as a prop
        inputDate: '',
        startTime: '',
        endTime: '',
        clientID: '',
        siteAddress: '',
        timeDetail: '',
      },
      clients: [], // Populate this array with client data
      file: null,
      imageUrls: [],
    }
  },
  mounted() {
    // Fetch clients or any necessary data
  },
  methods: {
    submitForm() {
      // Implement submission logic here
      console.log('Submitting:', this.shiftLogData)
      // Handle image upload separately or as part of form data
    },
    handleFiles() {
    //   this.imageUrls = []
    //   for (let i = 0; i < this.$refs.fileInput.files.length; i++) {
    //     const file = this.$refs.fileInput.files[i]
    //     this.imageUrls.push(URL.createObjectURL(file))
    //   }
    },
  },
}
</script>

<style>
/* Add any additional styling here */
</style>
