var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-input',{attrs:{"id":"userID","type":"hidden"},model:{value:(_vm.shiftLogData.userID),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "userID", $$v)},expression:"shiftLogData.userID"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date","label-for":"inputDate"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"inputDate","type":"date","placeholder":"Select date"},model:{value:(_vm.shiftLogData.inputDate),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "inputDate", $$v)},expression:"shiftLogData.inputDate"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Start Time","label-for":"startTime"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"startTime","type":"time","placeholder":"Select start time"},model:{value:(_vm.shiftLogData.startTime),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "startTime", $$v)},expression:"shiftLogData.startTime"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"End Time","label-for":"endTime"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"endTime","type":"time","placeholder":"Select end time"},model:{value:(_vm.shiftLogData.endTime),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "endTime", $$v)},expression:"shiftLogData.endTime"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Client Name","label-for":"clientID"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"clientID","options":_vm.clients,"label":"name","track-by":"id","reduce":function (client) { return client.id; },"placeholder":"Select a client"},model:{value:(_vm.shiftLogData.clientID),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "clientID", $$v)},expression:"shiftLogData.clientID"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Site Address","label-for":"siteAddress"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"siteAddress","placeholder":"Enter site address"},model:{value:(_vm.shiftLogData.siteAddress),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "siteAddress", $$v)},expression:"shiftLogData.siteAddress"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Time - Details","label-for":"timeDetail"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"timeDetail","placeholder":"Enter time details","rows":"3"},model:{value:(_vm.shiftLogData.timeDetail),callback:function ($$v) {_vm.$set(_vm.shiftLogData, "timeDetail", $$v)},expression:"shiftLogData.timeDetail"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Upload Images"}},[_c('b-form-file',{attrs:{"state":Boolean(_vm.file),"placeholder":"Choose one or more images...","multiple":""},on:{"change":_vm.handleFiles},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),(_vm.imageUrls.length > 0)?_c('div',_vm._l((_vm.imageUrls),function(url,index){return _c('div',{key:index},[_c('img',{staticClass:"img-thumbnail",staticStyle:{"max-width":"150px","margin-right":"10px"},attrs:{"src":url,"alt":("Shift Log Image " + (index + 1))}})])}),0):_vm._e()],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit Shift Log ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }